import d3 from './d3';
import BaseChart from './BaseChart';
import isWkHTMLToPDF from './isWkHTMLToPDF';

/* eslint indent: 0 */
/**
 * wrapper: valid d3 selector
 * barSeries: Array of Series
 *  Each Series data being an Array of numbers
 * pntSeries: Array of Series, the data of which should be the same length as barSeries
 *  these values can be blank, which will end up excluding them
 * lineSeries: see pntSeries
 * options: A charting.Options object
 */
function BaseBarChart(wrapper, barSeries, pntSeries, lineSeries, options, averageCharacterWidth) {
  var self = this;
  if (!('largestSeriesSetSize' in self) && self.largestSeriesSetSize === undefined) {
    self.largestSeriesSetSize = d3.max([barSeries.length, pntSeries.length, lineSeries.length]);
  }
  self.primarySeries = barSeries.length > 0 ? barSeries : pntSeries.length ? pntSeries : lineSeries;
  self.primarySeriesFirstSet = self.primarySeries[0];
  if (!options.getShowLegend()) {
    self.largestSeriesSetSize = 0; //Don't create room for the legends
  }
  // Apply the constructor of the "Parent" to `this` instance
  BaseChart.apply(self, [wrapper, self.largestSeriesSetSize, options, averageCharacterWidth]);

  self.sqPntSize = options.getPointSize();
  if (options.hasMaxBarWidth()) {
    self.maxBarWidth = options.getMaxBarWidth();
  } else {
    self.maxBarWidth = 50;
  }
  self.chartClass = 'bar-chart';
  if (options.getLollipopPoints()) {
    self.chartClass += ' lollipop-points';
  }

  var y, xScales;

  self.draw = function () {
    var bars = self.layersFromArrayOfCols(barSeries),
      points = layersForPointyData(pntSeries),
      lines = layersForPointyData(lineSeries);

    if (options.hasYAxisRange()) {
      self.yStackMax = options.getYAxisRange()[1];
      self.yStackMin = options.getYAxisRange()[0];
    } else {
      var yBarMax = d3.max(bars, function (layer) {
          return d3.max(layer, function (d) {
            return d.y0 + d.y;
          });
        }),
        yBarMin = d3.min(bars, function (layer) {
          return d3.min(layer, function (d) {
            return Math.min(d.y, d.y0);
          });
        }),
        yPntMax = d3.max(points, function (layer) {
          return d3.max(layer, function (d) {
            return d.y;
          });
        }),
        yPntMin = d3.min(points, function (layer) {
          return d3.min(layer, function (d) {
            return d.y == '' ? yPntMax : d.y;
          });
        }),
        yLineMax = d3.max(lines, function (layer) {
          return d3.max(layer, function (d) {
            return d.y;
          });
        }),
        yLineMin = d3.min(lines, function (layer) {
          return d3.min(layer, function (d) {
            return d.y == '' ? yLineMax : d.y;
          });
        });
      self.yStackMax = d3.max([yBarMax, yPntMax, yLineMax]);
      self.yStackMin = d3.min([yBarMin, yPntMin, yLineMin]);
      self.modifyYStackRanges();
    }

    // Ensure room for largest Y Axis Tick Label
    var leftChange;
    if (!options.getInvertAxes()) {
      var largestTickLength = String(self.formatValue(self.yStackMax)).length;
      leftChange =
        largestTickLength * averageCharacterWidth -
        self.margin.left +
        options.getLeftMarginBuffer();
      if (options.hasYAxisLabel()) {
        leftChange += 20;
      } //Ensure room for the axis label if needed
    } else {
      var longestLabel = options.hasLongestLabel()
        ? options.getLongestLabel()
        : d3.max(self.primarySeriesFirstSet.labels, function (lbl) {
            return lbl.length;
          });
      leftChange = longestLabel * averageCharacterWidth - self.margin.left;
    }
    if (leftChange > 0) {
      self.margin.left += leftChange;
      self.outerWidth += leftChange;
      self.width += leftChange;
    }

    var xAxisRange = [0, self.width];
    var yAxisRange = [self.height, 0];
    var xAxisPlacement = 'translate(0,' + self.height + ')';
    var yAxisPlacement = 'translate(0,0)';
    if (options.getInvertAxes()) {
      var tempForSwap = yAxisRange;
      yAxisRange = xAxisRange;
      xAxisRange = tempForSwap;

      tempForSwap = yAxisPlacement;
      yAxisPlacement = xAxisPlacement;
      xAxisPlacement = tempForSwap;
    }

    xScales = [];
    var xDomain;
    if (options.hasXAxisRange()) {
      xDomain = options.getXAxisRange();
      if (options.getInvertAxes()) {
        xDomain.reverse();
      }
      xScales.push(d3.scaleLinear().domain(xDomain).range(xAxisRange));
    } else {
      var nLabels = self.primarySeriesFirstSet.len();
      if (options.getGroupBySeries()) {
        nLabels *= self.seriesLabels(self.primarySeries).length;
      }
      if (options.hasGroupLabels() || options.getGroupBySeries()) {
        var nGroups = (
          options.hasGroupLabels()
            ? options.getGroupLabels()
            : self.seriesLabels(self.primarySeries)
        ).length;
        var gSize = self.width / nGroups;
        var lpg = nLabels / nGroups;
        for (var i = 0; i < nGroups; i++) {
          var start = gSize * i;
          var dStart = lpg * i;
          xScales.push(
            d3
              .scaleBand()
              .domain(d3.range(dStart, dStart + lpg))
              .rangeRound([start + 10, start + gSize - 10], 0.08),
          );
        }
      } else {
        xDomain = d3.range(nLabels);
        if (options.getInvertAxes()) {
          xDomain.reverse();
        }
        xScales.push(d3.scaleBand().domain(xDomain).rangeRound(xAxisRange, 0.08));
      }
    }

    y = d3.scaleLinear().domain([self.yStackMin, self.yStackMax]).range(yAxisRange);

    var maxLines = 1;

    var xAxes = xScales.map(function (x) {
      var a = options.getInvertAxes() ? d3.axisLeft() : d3.axisBottom();
      if (!options.hasXAxisRange() && !options.getInvertAxes()) {
        self.primarySeriesFirstSet.labels.forEach(function (lbl) {
          var wrappedLabel = self.wordWrap(lbl, x.bandwidth());
          var nLines = (wrappedLabel.match(/<br\s?\/?>/g) || []).length + 1;
          if (nLines > maxLines) {
            maxLines = nLines;
          }
        });
      }
      return a
        .scale(x)
        .tickSize(options.getXAxisVerticalLabels() ? 3 : 0)
        .tickPadding(6)
        .tickFormat(function (d) {
          if (!options.hasXAxisRange() && !options.getInvertAxes()) {
            return self.wordWrap(
              self.primarySeriesFirstSet.labels[d % self.primarySeriesFirstSet.labels.length],
              x.bandwidth(),
            );
          } else {
            return self.primarySeriesFirstSet.labels[d % self.primarySeriesFirstSet.labels.length];
          }
        });
    });
    if (maxLines > 1) {
      var adjust = (maxLines - 1) * 10;
      self.outerHeight += adjust;
      self.height += adjust;
    }

    if (options.hasGroupLabels() || options.getGroupBySeries()) {
      var maxGroupLabelLines = 0;
      var gls = options.hasGroupLabels()
        ? options.getGroupLabels()
        : self.seriesLabels(self.primarySeries);
      var xGDomain = d3.range(gls.length);
      var xG = d3.scaleBand().domain(xGDomain).rangeRound(xAxisRange, 0.08);
      gls.forEach(function (lbl) {
        if (lbl.length == 0) {
          return '';
        }
        var wrappedLabel = self.wordWrap(lbl, xG.bandwidth());
        var nLines = (wrappedLabel.match(/<br\s?\/?>/g) || []).length + 1;
        if (nLines > maxGroupLabelLines) {
          maxGroupLabelLines = nLines;
        }
      });
      if (maxGroupLabelLines > 0) {
        var adjustGroup = 15 + maxGroupLabelLines * 10;
        self.outerHeight += adjustGroup;
        self.height += adjustGroup;
      }
      var xGAxis = d3.axisBottom().scale(xG).tickSize(0).tickPadding(6);
      xGAxis.tickFormat(function (d) {
        return self.wordWrap(gls[d], xG.bandwidth());
      });
    }

    var yAxis = options.getInvertAxes() ? d3.axisBottom() : d3.axisLeft();
    yAxis
      .scale(y)
      .tickSize(3)
      .tickPadding(6)
      .tickFormat(function (d) {
        return self.formatValue(d);
      });

    self.adjustYAxisTicks(yAxis, options, self.yStackMax - self.yStackMin);

    var yAxis2;
    if (options.getSecondYAxis()) {
      yAxis2 = options.getInvertAxes() ? d3.axisTop() : d3.axisRight();
      yAxis2
        .scale(y)
        .ticks(yAxis.ticks()[0])
        .tickSize(yAxis.tickSize())
        .tickPadding(yAxis.tickPadding())
        .tickFormat(yAxis.tickFormat());
    }

    /* If there is a yStep passed in, ensure the y axis ticks are set
       to have that step -isaiah 2013-05-03 */
    if (options.hasYStep()) {
      var yStep = options.getYStep();
      var tickValues = d3
        .range(Math.ceil(self.yStackMin / yStep), Math.floor(self.yStackMax / yStep) + 1)
        .map(function (n) {
          return n * yStep;
        });
      yAxis.tickValues(tickValues);
      if (yAxis2) {
        yAxis2.tickValues(tickValues);
      }
    }

    var svg = self.createBaseChart();

    if (yAxis2) {
      if (options.getInvertAxes()) {
        // Shift the whole chart down a bit to make room for the second Y axis.
        var mainG = svg;
        var mainGTransform = self.getTranslation(mainG.attr('transform'));
        mainGTransform[1] = mainGTransform[1] + 20;
        mainG.attr('transform', 'translate(' + mainGTransform.join() + ')');
      }

      // Add the second Y axis.
      self.adjustYAxisTicks(yAxis2, options, self.yStackMax - self.yStackMin);
      var yAxis2Ele = svg.append('g');
      yAxis2Ele.attr('class', 'y axis').call(yAxis2).style({ 'stroke-width': '1px' });
      if (!options.getInvertAxes()) {
        yAxis2Ele.attr('transform', 'translate(' + self.width + ',0)');
      }
    }

    /* Draw the Y Axis Grid if they want it. Do this first such
        that it's under everything else */
    if (options.showYAxisGridLines()) {
      var axisBase = options.getInvertAxes() ? d3.axisBottom() : d3.axisLeft();
      svg
        .append('g')
        .attr('class', 'y axis grid')
        .attr('transform', yAxisPlacement)
        .call(
          axisBase
            .scale(y)
            .tickSize(-(options.getInvertAxes() ? self.height : self.width), 0, 0)
            .tickFormat('')
            .ticks(yAxis.ticks()[0]),
        )
        .style({ 'stroke-width': '1px' });
    }

    if (options.getChartValueBasis() !== null && options.getChartValueBasis() != 0) {
      var chartX = 'y',
        chartY = 'x';
      if (options.getInvertAxes()) {
        chartX = 'x';
        chartY = 'y';
      }
      svg
        .append('line')
        .attr(chartX + '1', function () {
          return y(options.getChartValueBasis());
        })
        .attr(chartX + '2', function () {
          return y(options.getChartValueBasis());
        })
        .attr('stroke', 'grey');
    }

    self.reserveSeriesNumbers();

    var bar = svg
      .append('g')
      .attr('class', 'bar-container')
      .selectAll('.bar')
      .data(bars)
      .enter()
      .append('g')
      .attr('class', function (d, i) {
        return self.seriesClass('bar', i);
      });

    var point = svg
      .append('g')
      .attr('class', 'point-container')
      .selectAll('.point')
      .data(points)
      .enter()
      .append('g')
      .attr('class', function (d, i) {
        return self.seriesClass('point', i);
      });

    var line = svg
      .append('g')
      .attr('class', 'line-container')
      .selectAll('.line')
      .data(lines)
      .enter()
      .append('g')
      .attr('class', function (d, i) {
        return self.seriesClass('line', i);
      });

    // Draw all the bars!
    var rectBar = svgObjBase(bar, 'rect');

    var xAxisPosFunc = function (d) {
      var xPos = d.x;
      if (options.getGroupBySeries()) {
        xPos += d.seriesNum * self.primarySeriesFirstSet.labels.length;
      }
      var x = self.getXScaleFromXValue(xPos, xScales);
      return x(xPos) + self.barXOffset(x, d);
    };
    var barWidthAttrFunc = function (d) {
      var x = self.getXScaleFromXValue(d, xScales);
      return self.barWidth(x, d);
    };
    var barLengthFunc = function (d) {
      var wdth = Math.abs(y(d.y0 + d.y) - y(d.y0));
      return isNaN(wdth) ? 0 : wdth;
    };
    chartX = 'x';
    chartY = 'y';
    var chartWidth = 'width',
      chartHeight = 'height',
      chartYFunc = function (d) {
        if (d.y > 0) {
          return y(d.y0 + d.y);
        } else {
          return y(d.y0);
        }
      };
    if (options.getInvertAxes()) {
      chartX = 'y';
      chartY = 'x';
      chartWidth = 'height';
      (chartHeight = 'width'),
        (chartYFunc = function (d) {
          if (d.y < 0) {
            return y(d.y0 + d.y);
          } else {
            return y(d.y0);
          }
        });
    }
    rectBar
      .attr(chartX, xAxisPosFunc)
      .attr(chartWidth, barWidthAttrFunc)
      .attr(chartY, chartYFunc)
      .attr(chartHeight, barLengthFunc);

    // Draw all the points!
    if (options.getLollipopPoints()) {
      // Lines
      svgObjBase(point, 'line')
        .attr(chartX + '1', function (d) {
          return xPos(d.x);
        })
        .attr(chartX + '2', function (d) {
          return xPos(d.x);
        })
        .attr(chartY + '1', function (d) {
          return y(d.y);
        })
        .attr(chartY + '2', function () {
          return y(options.getChartValueBasis());
        })
        .attr('stroke', 'grey')
        //Hide the lines for empty points
        .attr('visibility', function (d) {
          return d.y == '' ? 'hidden' : 'visible';
        });

      // Circles
      svgObjBase(point, 'circle')
        .attr('c' + chartX, function (d) {
          return xPos(d.x);
        })
        .attr('c' + chartY, function (d) {
          return y(d.y);
        })
        .attr('r', function (d, i, j) {
          return 7 - self.seriesNumberFromJ(j) * 2;
        })
        //Hide the empty points
        .attr('visibility', function (d) {
          return d.y == '' ? 'hidden' : 'visible';
        });
    } else {
      //rectPnt
      drawPointLayer(point, true).attr('transform', function (d, i, j) {
        return self.seriesNumberFromJ(j, 'point') == 1 ? pntRotate(xPos(d.x), y(d.y)) : '';
      });
    }

    // Draw all the line paths!
    svgObjBase(line, 'path').attr('d', function (d) {
      if (d.y === '' || d.y2 === '') {
        return '';
      }
      if (options.getInvertAxes()) {
        return 'M' + y(d.y) + ',' + xPos(d.x) + 'L' + y(d.y2) + ',' + xPos(d.x2);
      } else {
        return 'M' + xPos(d.x) + ',' + y(d.y) + 'L' + xPos(d.x2) + ',' + y(d.y2);
      }
    });

    // Draw all the line points!
    drawPointLayer(line, false);

    if (
      options.getValueDisplayInline() ||
      ['always-pdf', 'always', 'hover'].indexOf(options.getValueToolTips()) != -1
    ) {
      var eles = $(wrapper)
        .find('.point rect, .point circle, .line rect, .bar rect')
        .filter(function () {
          return !isNaN(this.__data__.y) && this.__data__.y != 0;
        });

      if (options.getValueDisplayInline() || options.getValueToolTips() == 'always-pdf') {
        eles.each(function () {
          var dp = $(this),
            valuePrefix = options.getValueToolTipsInclSeriesName() ? this.__data__.name + ': ' : '';
          var value = valuePrefix + self.formatToolTipValue(this.__data__.y),
            d3p = d3.select(this),
            wrp = d3.select(dp.parent().get(0)),
            ypos = Number(d3p.attr('y')) + Number(d3p.attr('height')) / 2,
            xpos = Number(d3p.attr('x')) + Number(d3p.attr('width'));

          wrp
            .append('rect')
            .attr('y', ypos - 7)
            .attr('x', xpos + 5)
            .attr('style', 'fill: #444')
            .attr('height', 10)
            .attr('width', 10)
            .attr('transform', 'rotate(45, ' + (xpos + 5) + ',' + (ypos - 7) + ')');
          wrp
            .append('rect')
            .attr('y', ypos - 10)
            .attr('x', xpos + 5)
            .attr('style', 'fill: #444')
            .attr('height', 20)
            .attr('width', value.length * 5 + 15);
          wrp
            .append('text')
            .attr('y', ypos - 7 + 12)
            .attr('x', xpos + 10)
            .text(value)
            .attr('style', 'fill: white');
        });
      } else {
        var opts = {
          gravity: 'w',
          title: function () {
            var valuePrefix = options.getValueToolTipsInclSeriesName()
              ? this.__data__.name + ': '
              : '';
            return valuePrefix + self.formatToolTipValue(this.__data__.y);
          },
        };
        if (options.getValueToolTips() == 'always') {
          opts.trigger = 'manual';
        }

        eles.tipsy(opts);

        if (options.getValueToolTips() == 'always') {
          eles.each(function () {
            var t = this;
            setTimeout(function () {
              $(t).tipsy('show');
            }, 2000);
          });
        }
      }
    }

    // Add X-Axis
    xAxes.forEach(function (xAxis) {
      var xAxisSVG = svg
        .append('g')
        .attr('class', 'x axis primarylabels')
        .attr('transform', xAxisPlacement)
        .call(xAxis);
      // Rotate x-axis labels
      if (options.getXAxisVerticalLabels()) {
        xAxisSVG
          .selectAll('text')
          .style('text-anchor', 'end')
          .attr('dx', '-.8em')
          .attr('dy', '.15em')
          .attr('transform', 'rotate(-65)');
      }
      xAxisSVG.style({ 'stroke-width': '1px' });
    });

    self.formatLabels(svg.selectAll('.axis.x g.tick').nodes());

    if (xGAxis != undefined) {
      svg
        .append('g')
        .attr('class', 'x axis grouplabels')
        .attr('transform', 'translate(0,' + self.height + ')')
        .call(xGAxis)
        .style({ 'stroke-width': '1px', 'font-weight': 'bold' });

      if (options.getGroupBySeries()) {
        var ticks = svg.selectAll('.grouplabels g');
        ticks.attr('class', function (d, i) {
          return self.seriesClass('bar', i);
        });
      }
    }

    self.formatLabels(svg.selectAll('.axis.x.grouplabels g.tick').nodes());

    // Add Y-Axis
    if (options.getShowYAxis()) {
      self.adjustYAxisTicks(yAxis, options, self.yStackMax - self.yStackMin);
      svg
        .append('g')
        .attr('class', 'y axis')
        .attr('transform', yAxisPlacement)
        .call(yAxis)
        .style({ 'stroke-width': '1px' });
    }

    if (options.getShowLegend() && !options.getGroupBySeries()) {
      if (options.getDrawPointLabels()) {
        drawPointLabels(point);
      }
      drawPointLegend(svg, options.getLegendPosition());
      self.drawBarLegend(svg, options.getLegendPosition());
      drawLineLegend(svg, options.getLegendPosition(), options.getLineLegendSize());
    }

    drawAxisLabels(svg);

    self.removeDefaultStyleAttrsFromAxesAndWrapper();

    if (options.hasFooterImage()) {
      const footerImage = svg
        .append('svg:image')
        .attr('xlink:href', options.getFooterImage())
        .attr('height', 20)
        .attr('x', 10)
        .attr('y', self.height + self.margin.bottom);
      // Reposition once image is loaded to the proper width
      $(() => {
        const footerImageWidth = footerImage.style('width').replace('px', '');
        footerImage.attr('x', self.width - footerImageWidth);
      });
    }

    if (!isWkHTMLToPDF() && options.getAllowDownload()) {
      this.addDownloadLink(wrapper, averageCharacterWidth);
    }
  };

  /* Psuedo Private methods. These are in the `this` scope so they
      can be used or overriden by children */

  /* When we have very few bars, we make them skinnier to avoid odd
      looking charts. Doing so requires us to provide an X offset for
      the bars so the bar will still be centered with it's label */
  self.barXOffset = function (x, d) {
    return (x.bandwidth() - self.barWidth(x, d)) / 2;
  };

  /* Gets maxBarWidth or the width of the bar column space as decided by
      dividing the width of the chart by number of bars (done via d3 domain ranges) */
  self.barWidth = function (x) {
    return Math.min(self.maxBarWidth, x.bandwidth());
  };

  // This is to provide padding at the top and bottom of data
  self.modifyYStackRanges = function () {
    self.yStackMin =
      self.yStackMin >= options.getChartValueBasis() && self.yStackMin / self.yStackMax < 0.1
        ? options.getChartValueBasis()
        : self.yStackMin - self.yStackMax * 0.1;
    self.yStackMax *= 1.1;
  };

  self.reserveSeriesNumbers = function () {
    //by default this does nothing.
  };

  /* Private methods */
  /* Because function definitions are hoisted, we can define them down here
      but still override them above for children */

  function drawLineLegend(svg, legendPosition, legendSize) {
    var lX, lY, labelWidth;
    switch (legendPosition) {
      case 'bottom':
        switch (legendSize) {
          case 'small':
            lX = self.outerWidth / 2;
            labelWidth = self.outerWidth / 2;
            break;
          case 'full-width':
            lX = 0;
            labelWidth = self.outerWidth;
            break;
        }
        lY = self.outerHeight - self.margin.bottom + (options.getXAxisVerticalLabels() ? 70 : 40);
        break;
      case 'right':
        lX = self.outerWidth - 190;
        lY = self.outerHeight / 2;
        labelWidth = 150;
        break;
      case 'top':
        switch (legendSize) {
          case 'small':
            lX = self.outerWidth / 2;
            labelWidth = self.outerWidth / 2;
            break;
          case 'full-width':
            lX = 0;
            labelWidth = self.outerWidth;
            break;
        }
        lY = 10 - self.margin.top;
        break;
    }
    var w = 35;
    var legend = svg.append('g').attr('class', 'legend');
    var lines = legend.append('g');
    var labels = legend.append('g');
    var extraLines = 0;
    $.map(lineSeries, function (series, i) {
      var yOff = (i + extraLines) * self.legendItemHeight;
      var tY = lY + yOff;
      var pathY = tY - (self.legendItemHeight - 4) / 2;
      lines
        .append('g')
        .attr('class', self.seriesClass('line', i))
        .append('path')
        .attr('d', 'M' + lX + ',' + pathY + 'L' + (lX + w) + ',' + pathY);
      var wrappedLabel = self.wordWrap(series.name, labelWidth);
      extraLines += (wrappedLabel.match(/<br\s?\/?>/g) || []).length;
      labels
        .append('text')
        .attr('x', lX + 5 + w)
        .attr('y', tY)
        .text(wrappedLabel);
    });
    self.formatLabels(labels.nodes());
  }

  // Adds labels next to floating points when applicable
  function drawPointLabels(point) {
    point
      .selectAll('text')
      .data(function (d) {
        return d;
      })
      .enter()
      .append('text')
      .attr('x', function (d) {
        return xPos(d.x) + self.sqPntSize + 5;
      })
      .attr('y', function (d) {
        return y(d.y) + 3;
      })
      .text(function (d) {
        return d.label;
      });
  }

  function drawPointLayer(layer, hasRotatedPoints) {
    var chartX = 'x',
      chartY = 'y';
    if (options.getInvertAxes()) {
      chartX = 'y';
      chartY = 'x';
    }
    return (
      svgObjBase(layer, 'rect')
        .attr(chartX, function (d) {
          return xPos(d.x) - self.sqPntSize / 2;
        })
        .attr('width', hasRotatedPoints ? pntSize : self.sqPntSize)
        .attr(chartY, function (d) {
          return y(d.y) - self.sqPntSize / 2;
        })
        .attr('height', hasRotatedPoints ? pntSize : self.sqPntSize)
        //Hide the empty points
        .attr('visibility', function (d) {
          return d.y == '' ? 'hidden' : 'visible';
        })
    );
  }

  function drawPointLegend(svg, legendPosition) {
    var lX, lY;
    switch (legendPosition) {
      case 'bottom':
        lX = self.width / 2;
        lY = self.outerHeight - self.margin.bottom + 40;
        if (
          (!options.getInvertAxes() && options.hasXAxisLabel()) ||
          (options.getInvertAxes() && options.hasYAxisLabel())
        ) {
          lY += 20;
        }
        break;
      case 'right':
        lX = self.outerWidth - 190;
        lY = self.margin.top;
        break;
      case 'top':
        lX = self.width / 2;
        lY = 10 - self.margin.top;
        break;
    }
    var legend = svg.append('g').attr('class', 'legend');
    var pnts = legend.append('g');
    var labels = legend.append('g');
    var extraLines = 0;
    $.map(pntSeries, function (series, i) {
      var w = pntSize(0, 0, i);
      var yOff = (i + extraLines) * self.legendItemHeight;
      var tY = lY + yOff;
      var rY = tY - self.sqPntSize + i * 3;
      if (options.getLollipopPoints()) {
        // Circles
        pnts
          .append('g')
          .attr('class', self.seriesClass('point', i))
          .append('circle')
          .attr('cx', lX + 3)
          .attr('cy', rY + 3)
          .attr('r', 6);
      } else {
        var t = i == 1 ? pntRotate(lX, rY + self.sqPntSize / 2) : '';
        pnts
          .append('g')
          .attr('class', self.seriesClass('point', i))
          .append('rect')
          .attr('width', w)
          .attr('height', w)
          .attr('transform', t)
          .attr('x', lX)
          .attr('y', rY)
          .attr('dy', 0);
      }
      var wrappedLabel = self.wordWrap(series.name, self.outerWidth / 2 - w - 10);
      extraLines += (wrappedLabel.match(/<br\s?\/?>/g) || []).length;
      labels
        .append('text')
        .attr('x', lX + 5 + self.sqPntSize)
        .attr('y', tY)
        .text(wrappedLabel);
      self.formatLabels(labels.nodes());
    });
  }

  // Draws a rotated label for the Y Axis
  function drawAxisLabels(svg) {
    if (options.hasYAxisLabel()) {
      var yAxisLabel = svg
        .append('text')
        .style('text-anchor', 'middle')
        .text(options.getYAxisLabel());
    }
    if (options.hasXAxisLabel()) {
      var xAxisLabel = svg
        .append('text')
        .style('text-anchor', 'middle')
        .text(options.getXAxisLabel());
    }
    var btmLabel, leftLabel;
    if (options.getInvertAxes()) {
      btmLabel = yAxisLabel;
      leftLabel = xAxisLabel;
    } else {
      btmLabel = xAxisLabel;
      leftLabel = yAxisLabel;
    }
    if (btmLabel !== undefined) {
      btmLabel.attr('x', self.width / 2).attr('y', self.outerHeight - self.margin.bottom + 30);
    }
    if (leftLabel !== undefined) {
      leftLabel
        .attr('transform', 'rotate(-90)')
        .attr('y', 0 - self.margin.left)
        .attr('x', 0 - self.height / 2)
        .attr('dy', '1em');
    }
  }

  // function hyphenize(str) {
  //   return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  // }

  // Generates D3-esque layers from the series of points passed in
  function layersForPointyData(pointyData) {
    var l = [];
    pointyData.map(function (pts, i) {
      l[i] = [];
      d3.range(pointyData[0].len()).map(function (lNum) {
        var pt = parseFloat(pts.data[lNum]);
        if (!isNaN(pt)) {
          l[i][lNum] = { x: lNum, y: pt };
          if (pts.hasLabels()) {
            l[i][lNum].label = pts.labels[lNum];
            l[i][lNum].name = l[i][lNum].label;
          }
        } else {
          l[i][lNum] = { x: lNum, y: '' };
        }
        var nextPt = parseFloat(pts.data[lNum + 1]);
        l[i][lNum].x2 = lNum + 1;
        l[i][lNum].y2 = isNaN(nextPt) ? '' : nextPt;
      });
    });
    return l;
  }

  // Returns the Transform value to rotate a point 45 degrees around an x/y
  function pntRotate(x, y) {
    return 'rotate(45,' + (x + self.sqPntSize / 2) + ',' + y + ')';
  }

  /* The second points (array index 1) will be rotated but we
      want the final width to be the same. -isaiah 2013-05-03 */
  function pntSize(d, i, j) {
    return j == 1 ? self.sqPntSize / Math.sqrt(2) : self.sqPntSize;
  }

  /* Replaces all `objType` in a layer with new `objType`s mapped to
      the data for that layer */
  function svgObjBase(layerSet, objType) {
    return layerSet
      .selectAll(objType)
      .data(function (d) {
        return d;
      })
      .enter()
      .append(objType);
  }

  /* x(tX) gets us the scaled position. Then we need to
    add half of the width of the bars. Then subtract half
    the width of the point. This will center the point
    on the bars -isaiah 2013-05-03 */
  function xPos(tX) {
    var x = self.getXScaleFromXValue(tX, xScales);
    if (options.hasXAxisRange()) {
      return x(tX);
    } else {
      return x(tX) + x.bandwidth() / 2;
    }
  }
}
export default BaseBarChart;
